<template>
	<v-row class="pb-3" dense>
		<v-col cols="12" class="pt-0">
			<label>{{ label }}</label>
		</v-col>
		<v-col cols="12" class="pb-0">
			<v-text-field
				@keyup="checkEmail($event)"
				single-line
				outlined
				dense
				height="48"
				color="#CACEDA"
				type="email"
				:placeholder="placeholder"
				v-model="displayValue"
				:rules="emailRules"
			></v-text-field>
		</v-col>
	</v-row>
</template>

<script>
import { emailCheck } from "../services/distributorservice";
export default {
	props: {
		label: String,
		placeholder: String,
		value: String,
		type: String
	},

	data() {
		return {
			email_exists: true,
			emailRules: [
				v => !!v || "E-mail is required",
				v => /.+@.+/.test(v) || "E-mail must be valid"
			]
		};
	},

	computed: {
		displayValue: {
			get() {
				return this.value;
			},
			set(setvalue) {
				this.$emit("input", setvalue);
			}
		}
	},

	methods: {
		async checkEmail(event) {
			if (this.type === "user" || this.type === "company") {
				let str = event.target.value;
				let s = str.indexOf("@");
				if (s > 0) {
					let data = await emailCheck(str, this.type);
					data === true
						? (this.emailRules = [
								v => !!v || "E-mail is required",
								v => /.+@.+/.test(v) || "E-mail must be valid"
						  ])
						: (this.emailRules = [
								v => !!v || "E-mail is required",
								v => /.+@.+/.test(v) || "E-mail must be valid",
								v =>
									this.email_exists === false ||
									"This email already exists"
						  ]);
				}
			} else {
				this.email_exists = false;
			}
		}
	}
};
</script>